// Introduction.tsx
import React from 'react';
import { Text, Pressable, View, StyleSheet } from 'react-native';

const SectionAbout = ({ isMobile, openLink, styles }: { isMobile: boolean, styles: any, openLink: (url: string) => void }) => {


    const hashtags = {
        'WordPress': 10,
        'ProjectManagement': 10,
        'PHP': 10,
        'ProductDevelopment': 10,
        'API': 8,
        'Python': 8,
        'SaaS': 8,
        'ReactNative': 7,
    };

    const calculateFontSize = (weight) => {
        // Assuming the range of font size is 12px to 48px
        const minFontSize = isMobile ? 8 : 24;
        const maxFontSize = isMobile ? 24 : 48;
        const scale = (weight / 10) * (maxFontSize - minFontSize);
        return minFontSize + scale;
    };

    return (
        <View style={styles.stackOrRow}>
            <View style={{flex:1}}>
                <Text style={{
                    fontSize: isMobile ? 48 : 64,
                    color: '#fff',
                    marginBottom: isMobile ? 20 : 40,
                    fontWeight: 100,
                }}>
                    Hello there!
                </Text>

                <Text style={{
                    fontSize: 24,
                    color: '#fff',
                    marginBottom: 5,
                    fontWeight: 150,
                    // textAlign: 'justify',
                }}>
                    My name is <Text style={styles.bold}>Matt</Text> and I am a <Text style={styles.bold}>software developer, project manager & product owner</Text> with{' '}
                    <Text style={styles.bold}>17 years of experience</Text>.

                    {'\n'}
                    {'\n'}
                    My main focus are <Text style={styles.bold}>WordPress</Text> solutions,{' '}
                    <Text style={styles.bold}>React Native</Text> mobile apps, and{' '}
                    <Text style={styles.bold}>SaaS products with Python</Text> / other server-side scripts.

                    {'\n'}
                    {'\n'}
                    I currently serve as <Text style={styles.bold}>Chief Product and Technology Officer</Text>

                    {' '} at {' '}

                    <Pressable onPress={() => openLink('https://www.peepso.com')}>
                        <Text style={styles.link}>PeepSo.com</Text>
                    </Pressable>

                    ,{' '}
                    <Pressable onPress={() => openLink('https://www.awedesk.com')}>
                        <Text style={styles.link}>Awedesk.com</Text>
                    </Pressable>

                    {' '}and{' '}

                    <Pressable onPress={() => openLink('https://www.listowp.com')}>
                        <Text style={styles.link}>ListoWP.com</Text>
                    </Pressable>.

                    {'\n'}
                    {'\n'}
                </Text>
            </View>
            <View style={{
                flex:1,
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                padding: isMobile ? 0 : 0,
                marginLeft: isMobile ? 0 : 10,
                backgroundColor: isMobile ? "transparent" : 'rgba(255,255,255,0.0420)',
                borderRadius: 10,
            }}>
                {Object.entries(hashtags).map(([hashtag, weight], index) => (
                    <Text
                        key={index}
                        style={{
                            color: 'rgba(255,255,255,0.5)',
                            fontWeight: '100',
                            marginBottom: 5,
                            marginHorizontal: 10,
                            fontSize: calculateFontSize(weight)
                        }}>
                        #{hashtag}
                    </Text>
                ))}
            </View>

        </View>
    );
};

export default SectionAbout;
