// Introduction.tsx
import React from 'react';
import { Image } from 'expo-image'
import { Text, Pressable, View, StyleSheet } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWordpress, faReact, faPython } from '@fortawesome/free-brands-svg-icons'; // Logo icons
import { faLinkedin,faLinkedinIn, faXTwitter } from '@fortawesome/free-brands-svg-icons'; // For LinkedIn and email logos
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';

const SectionHead = ({ isMobile, openLink, styles }: { isMobile: boolean, styles: any, openLink: (url: string) => void }) => {

    const IconLink = ({ icon, url, last=false }) => {
        return (
            <Pressable onPress={() => openLink(url)} style={{
                width: isMobile ? 48 : 64,
                aspectRatio:1,
                marginRight: last ? 0 : 15,
                padding:15,
                backgroundColor: 'rgba(255,255,255,0.1)',
                borderRadius: 100,
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <FontAwesomeIcon icon={icon} color="#462900" style={{ fontSize: isMobile ? 32 : 48}} />
            </Pressable>
        )
    }

    return (
        <View style={styles.stackOrRow}>

            <View style={{
                flex: isMobile ? 2: 1,
                height:"100%",
                alignItems: isMobile ? 'center' : 'flex-end',
                justifyContent: isMobile ? 'center' : 'center',
            }}>
                <View style={{
                    backgroundColor: "rgba(255,255,255,0.1)",
                    borderRadius: 200,
                    margin: isMobile ? 20 : 20,
                    padding:15,
                }}>
                <Image source={require('./assets/images/me.jpg')}
                       style={{
                           height: 350,
                           maxHeight:"90%",
                           aspectRatio: 1,
                           borderRadius: 200,

                       }}
                       contentFit="cover"
                       transition={1500}
                />
                </View>

            </View>

            <View style={{
                flex:  isMobile ? 1 : 1, // Adjust flex based on layout
                alignItems: isMobile ? 'center' : 'flex-start',
                justifyContent: isMobile ? 'center' : 'center',
                padding: isMobile ? 0 :10,
            }}>

                <View style={{alignItems: 'center', justifyContent: 'center'}} >
                    <Text style={{ fontSize: isMobile ? 46 : 64, fontWeight: 'bold', color: '#111', marginBottom: 10,  fontWeight: 100 }}>
                        Matt Jaworski
                    </Text>

                    <Text style={{ fontSize: isMobile ? 24 : 32, color: '#222', fontWeight: 100 }}>Level 38 • Legendary Nerd</Text>

                    <View style={{ marginTop: 20,flexDirection: 'row' }} >
                        <IconLink icon={faLinkedinIn} url="https://linkedin.com/in/jaworskimatt" />
                        <IconLink icon={faEnvelope} url="mailto:matt@jwr.sk" />
                        <IconLink icon={faXTwitter} url="https://x.com/jaworskimatt" last={true} />
                    </View>
                </View>
            </View>
        </View>
    );
};

export default SectionHead;
