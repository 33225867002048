import React, { useEffect, useRef } from 'react';
import { View, Text, StyleSheet, ScrollView, Animated, useWindowDimensions,  Pressable, Linking  } from 'react-native';
import Section from './Section';
import { Image } from 'expo-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWordpress, faReact, faPython } from '@fortawesome/free-brands-svg-icons'; // Logo icons
import { faLinkedin,faLinkedinIn, faXTwitter } from '@fortawesome/free-brands-svg-icons'; // For LinkedIn and email logos
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faHandSpock} from '@fortawesome/free-regular-svg-icons';
import SectionHead from './SectionHead';
import SectionAbout from './SectionAbout';
import SectionProjects from './SectionProjects';
import SectionResume from "./SectionResume";

export default function App() {

    const { width } = useWindowDimensions();
    const isMobile = width < 1200;

    // Shared animated values
    const evenAngle = useRef(new Animated.Value(Math.random() * 360)).current;
    const oddAngle = useRef(new Animated.Value(Math.random() * 360)).current;

    const bgColor = "#e3ac26";
    useEffect(() => {
        // Change the background color of the "status bar"
        document.body.style.backgroundColor = bgColor;
    }, []);

    useEffect(() => {
        const animate = (animatedValue: Animated.Value) => {
            Animated.loop(
                Animated.timing(animatedValue, {
                    toValue: animatedValue.__getValue() + 360, // Full rotation
                    duration: 60000, // 60 seconds for a smooth cycle
                    useNativeDriver: false,
                })
            ).start();
        };

        // animate(evenAngle);
        // animate(oddAngle);
    }, []);

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: '#212131',
        },
        scrollContent: {
            flexGrow: 1,
        },
        title: {
            fontSize: 24,
            fontWeight: 'bold',
            marginBottom: 10,
            color: '#fff',
        },
        content: {
            fontSize: 16,
            lineHeight: 24,
            color: '#fff',
        },
        link: {
            fontWeight: '250',
            borderBottomColor: 'rgba(255,255,255,0.2)',
            borderBottomWidth: 1,
        },
        bold: {
            fontWeight: 'bold',
        },
        stackOrRow: {
            flexDirection: isMobile ? 'column' : 'row',
            flex: 1,
            flexGrow: 1,
        },

    });

    const section1IconSize = 64;


    const openLink = (url: string) => {
        Linking.openURL(url).catch((err) => console.error("Failed to open URL:", err));
    };


    return (
        <View style={styles.container}>
            <ScrollView contentContainerStyle={styles.scrollContent}>

                <Section colors={[bgColor, '#c39b06', bgColor]} border="#a36a16" animatedAngle={evenAngle} isMobile={isMobile} isFirst={true}>
                    <SectionHead openLink={openLink} styles={styles} isMobile={isMobile}/>
                </Section>


                <Section colors={['#421c3b', '#171b3e', '#421c3b', '#272b2e']}  border="#220b2b" animatedAngle={oddAngle} isMobile={isMobile}>
                    <SectionAbout isMobile={isMobile} openLink={openLink} styles={styles} />
                </Section>

                <Section colors={['#004020', '#005025','#003015']}  border="#053020" animatedAngle={evenAngle} isMobile={isMobile}>
                    <SectionProjects isMobile={isMobile} openLink={openLink} styles={styles} />
                </Section>

                <Section colors={['#421c3b', '#171b3e',]}   border="#220b2b" animatedAngle={evenAngle} isMobile={isMobile}>
                    <SectionResume isMobile={isMobile} openLink={openLink} styles={styles} />
                </Section>

                <Section  colors={[bgColor, '#c39b06', bgColor]}  animatedAngle={oddAngle} isMobile={isMobile}>
                    <View style={{alignItems:"center", justifyContent:"center", flexDirection:"row"}}>
                        <FontAwesomeIcon size="2x" icon={faHandSpock} style={{fontWeight:100}} /><Text style={{ fontSize: isMobile ? 32 :48, color: '#222', fontWeight: 100 }}>{' '}Live Long And Prosper</Text>
                    </View>
                </Section>

            </ScrollView>
        </View>
    );
}


