import React, {useEffect, useRef, useState} from 'react';
import { Animated, Text, Pressable, View, StyleSheet } from 'react-native';
import { Image } from 'expo-image';
import {faChevronDown, faCircleChevronDown} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SectionResume from "./SectionResume";

const SectionProjects = ({ isMobile, openLink, styles }: { isMobile: boolean, styles: any, openLink: (url: string) => void }) => {

    const ProjectLogo = ({ source, url }) => {
        const imageHeight = 50;

        return (
            <Pressable onPress={() => openLink(url)}>
                <Image
                    source={source}
                    style={{
                        marginBottom: 20,
                        height: imageHeight,
                        aspectRatio: 150 / 30,
                        opacity: 0.8,
                    }}
                    contentFit="contain"
                />
            </Pressable>
        );
    };

    const ProjectDesc = ({ subtitle, desc }) => {
        return (
            <>
                <View>
                    <Text style={{
                        textAlign: 'center',
                        color: "#eee",
                        fontSize: isMobile ? 14 : 22,
                        marginBottom: 20,
                    }}>
                        {subtitle.split('<br>').map((line, index) => (
                            <Text key={index}>{line}{'\n'}</Text>
                        ))}
                    </Text>
                </View>
                <Text style={{
                    color: "#fff",
                    fontSize: isMobile ? 16 : 20,
                    marginHorizontal: 10,
                    textAlign: 'justify',
                }}>
                    {desc.split('<br>').map((line, index) => (
                        <Text key={index}>{line}{'\n'}</Text>
                    ))}
                </Text>
            </>
        );
    };

    const sectionStyles = StyleSheet.create({
        desc: {
            flex: 1,
            alignItems: "center",
            marginBottom: isMobile ? 75 : 10,
            marginHorizontal: isMobile ? 0 : 20,
            padding: isMobile ? 0 : 20,
            backgroundColor: isMobile ? " transparent" : "rgba(255,255,255,0.069)",
            borderRadius: 10,
        },
        last: {
            marginBottom: isMobile ? 50 : 10,
        },
    });

    return (
        <>
            <View style={{ alignItems: "center", marginBottom: 50 }}>
                <Text style={{ color: "white", fontSize: 42, fontWeight: "100" }}>What I'm working on</Text>
            </View>

            <View style={styles.stackOrRow}>
                <View style={[sectionStyles.desc]}>
                    <ProjectLogo url="https://peepso.com" source={require('./assets/images/peepso.svg')} />
                    <ProjectDesc
                        subtitle="Since 2017<br>Freemium WordPress Plugin & Theme •  Mobile App"
                        desc="A WordPress plugin that allows you to quickly and easily add a social network or an online community right inside your WordPress site."
                    />
                </View>

                <View style={[sectionStyles.desc]}>
                    <ProjectLogo url="https://awedesk.com" source={require('./assets/images/awedesk.svg')} />
                    <ProjectDesc
                        subtitle="Since 2024<br>Paid WordPress Plugin • SaaS • Mobile App"
                        desc="A highly intuitive & easy to use helpdesk WordPress plugin. With unlimited departments, agents, email piping, it's a complete support tickets solution.
                              <br><br>Awedesk is both a WordPress plugin & a SaaS product, and a dedicated mobile app for support agents works for both."
                    />
                </View>

                <View style={[sectionStyles.desc, sectionStyles.last]}>
                    <ProjectLogo url="https://listowp.com" source={require('./assets/images/listowp.svg')} />
                    <ProjectDesc
                        subtitle="Since 2023<br>Paid WordPress Plugin"
                        desc="A beautiful and modern to-do & reminders WordPress plugin. Featuring kanban boards and (in the near future) team collaboration, it aims to centralize project management inside your WordPress website."
                    />
                </View>
            </View>
        </>
    );
};

export default SectionProjects;
