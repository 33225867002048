import React, { useRef, useState } from 'react';
import { Animated, Text, Pressable, View, StyleSheet } from 'react-native';
import { Image } from 'expo-image';
import {faChevronDown, faCircleChevronDown} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SectionResume = ({ isMobile, openLink, styles }: { isMobile: boolean, styles: any, openLink: (url: string) => void }) => {

    const sectionStyles = StyleSheet.create({
        h1: {
            color: "#fff",
            fontSize: 36,
            fontWeight: 100,
        },
        box: {
            flex: 1,
            marginVertical: isMobile ? 35 : 20,
            marginHorizontal: isMobile ? 0 : 20,
            padding: isMobile ? 0 : 20,
            backgroundColor: isMobile ? " transparent" : "rgba(255,255,255,0.069)",
            borderRadius: 10,
        }
    })
    const header = (text: string, type='h1', center=false) => {
        return (
            <View style={{alignItems: 'center'}}>
                {'\n'}
                <Text style={sectionStyles['h1']}>{text}</Text>
                {'\n'}
            </View>
        )
    }

    const paragraph = (children: React.ReactNode) => {
        return (
            <Text style={{ color: "#ddd", fontSize: 16, marginTop: 25, lineHeight:20}}>
                {children}
            </Text>
        );
    };

    return (
        <View>
            <View style={{ alignItems: "center", marginBottom: 25 }}>
                <Text style={{ color: "#fff", fontSize: 42, fontWeight: "100" }}>What I can do</Text>
            </View>
            <View>


                    <View style={styles.stackOrRow}>
                        <View style={sectionStyles.box}>
                            {header('Technology', 'h2', true)}
                            {paragraph(
                                <>
                                    <Text style={{ fontWeight: "bold" }}>WordPress / Backend Web Development:</Text> I mostly work with REST APIs, PHP & MySQL.
                                    {"\n\n"}
                                    <Text style={{ fontWeight: "bold" }}>Mobile Apps:</Text> I develop iOS and Android apps using React Native (Expo & bare workflow), utilizing both APIs and WebViews (depending on the project).
                                    {"\n\n"}
                                    <Text style={{ fontWeight: "bold" }}>SaaS / Server Automation:</Text> I work a lot with Python combined with shell scripts and various utilities (WP CLI, WP Toolkit, CPanel/WHM API) building extensive server-side scripts.
                                    {"\n\n"}
                                    In addition to specific programming languages, I am well-versed in the usual tools of the trade: Linux, servers, version control (git), etc. -  I am generally comfortable in any technical environment and like to think I can learn anything if needed.
                                </>
                            )}
                        </View>

                        <View style={sectionStyles.box}>
                            {header('Business Logic', 'h2', true)}
                            {paragraph(
                                <>
                                    Translating business concepts and product/feature ideas into technical requirements and architecture is probably my favorite part of the job.
                                    {"\n\n"}
                                    As an expert in big-picture thinking and architectural design, I pave the road from business goals to actual engineering solutions.
                                    {"\n\n"}
                                    This skill works both ways - it allows me to explain technical ideas and issues to non-programmers so that we can always find common ground.
                                </>
                            )}
                        </View>
                    </View>

                    <View style={styles.stackOrRow}>
                        <View style={sectionStyles.box}>
                            {header('Communication', 'h2', true)}
                            {paragraph(
                                <>
                                    As a seasoned CPTO, I believe in an effective yet empathetic communication style both with my teammates and customers. I specialize in distilling complex goals into clear directives.
                                    {"\n\n"}
                                    This approach helped me successfully drive our daily product development for many years.
                                    {"\n\n"}
                                    I am a native Polish speaker but over the years English "became" my first language. I also speak pretty decent Spanish.
                                </>
                            )}
                        </View>

                        <View style={sectionStyles.box}>
                            {header('Collaboration & Leadership', 'h2', true)}
                            {paragraph(
                                <>
                                    I'm committed to building a collaborative environment, maximizing each team member's strengths, and amplifying our collective potential. Having always worked with people from around the globe, I always make sure to learn about their culture and customs to ensure a respectful and frictionless cooperation.
                                    {'\n'}{'\n'}
                                    With a primary focus on achieving measurable outcomes, I lead with a "solution-first" mentality. I cultivate a culture that values solutions over pointing fingers, ensuring the teams are geared towards success and rapid innovation, rather than fear of failure.
                                </>
                            )}
                        </View>
                    </View>
                </View>


        </View>
    );
};

export default SectionResume;
