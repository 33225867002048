import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import Animated from 'react-native-reanimated';

interface SectionProps {
    colors?: string[]; // Gradient colors
    border?: string; // Border color
    isFirst?: boolean; // Is this the first section?
    animatedAngle: Animated.Value<number>; // Shared Animated angle
    children: React.ReactNode; // Content passed as children
}

const Section: React.FC<SectionProps> = ({ isFirst = false, colors = ['#fff', '#fff'], border, animatedAngle, isMobile,children }) => {
    const [gradientDirection, setGradientDirection] = useState({ start: { x: 0, y: 0 }, end: { x: 1, y: 1 } });

    useEffect(() => {
        const id = animatedAngle.addListener(({ value }) => {
            const angleInRadians = (value * Math.PI) / 180;
            const start = {
                x: 0.5 - 0.5 * Math.cos(angleInRadians),
                y: 0.5 - 0.5 * Math.sin(angleInRadians),
            };
            const end = {
                x: 0.5 + 0.5 * Math.cos(angleInRadians),
                y: 0.5 + 0.5 * Math.sin(angleInRadians),
            };
            setGradientDirection({ start, end });
        });

        return () => animatedAngle.removeListener(id); // Clean up listener on unmount
    }, [animatedAngle]);

    const styles = StyleSheet.create({
        section: {
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: "row",
        },
        innerContainer: {
            flex:1,
            width:"100%",
            flexGrow: true,
            maxWidth: 1800,
            maxHeight:2000,
            paddingHorizontal: isMobile ? 32 : 24,
            paddingVertical: isMobile ? 32 : 64,
        }
    });

    return (
        <View style={[styles.section, {height: isFirst && isMobile? "100vh" : "auto",borderBottomWidth: border ? 10 : 0, borderColor: border || "transparent" }]}>
            <LinearGradient
                colors={colors}
                start={gradientDirection.start}
                end={gradientDirection.end}
                style={StyleSheet.absoluteFillObject}
            />
            <View style={styles.innerContainer}>{children}</View>
        </View>
    );
};



export default Section;
